import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IArticlesState } from '@store/articles/articles-state.interface';
import { articlesFK } from '@store/articles/articles.reducer';

const selectFeature = createFeatureSelector<IArticlesState>(articlesFK);

export const selectActiveArticleCategoryUuid = createSelector(
  selectFeature,
  (state: IArticlesState) => state.activeCategoryUuid
);

export const selectArticlesSearchState = createSelector(
  selectFeature,
  (state: IArticlesState) => state.searchState
);

export const selectArticleBlob = createSelector(
  selectFeature,
  (state: IArticlesState) => state.articleBlob
);

export const selectIsArticleBlobDownloading = createSelector(
  selectFeature,
  (state: IArticlesState) => state.isLoading.articleBlob
);

export const selectArticle = createSelector(
  selectFeature,
  (state: IArticlesState) => state.article
);

export const selectInitialCategoryArticles = createSelector(
  selectFeature,
  (state: IArticlesState) => state.initialCategoryArticles
);

export const selectUserMappedArticles = createSelector(
  selectFeature,
  (state: IArticlesState) => state.userMappedCategoryArticles
);
