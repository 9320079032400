import { createReducer, on } from '@ngrx/store';
import { IArticlesState } from '@store/articles/articles-state.interface';
import {
  articlesListComponentInitialized,
  articlesSortAndSearchResult,
  getArticle,
  getArticleBlob,
  getArticleBlobFailure,
  getArticleBlobSuccess,
  getArticleFailure,
  getArticleSuccess,
  getArticlesFailure,
  getArticlesSuccess,
  setActiveArticlesCategoryUuid,
  setArticlesSearchState,
  unsetArticle,
  unsetArticleBlob,
  unsetArticles,
} from '@store/articles/articles.actions';

export const articlesFK = 'articlesFK';

export const initialState: IArticlesState = {
  activeCategoryUuid: null,
  article: null,
  articleBlob: null,
  initialCategoryArticles: null,
  userMappedCategoryArticles: null,
  searchState: '',
  error: {
    article: null,
    articleBlob: null,
    articles: null,
  },
  isLoading: {
    article: false,
    articleBlob: false,
    articles: false,
  },
};

export const articlesReducer = createReducer(
  initialState,

  on(
    setActiveArticlesCategoryUuid,
    (state, action): IArticlesState => ({
      ...state,
      activeCategoryUuid: action.categoryUuid,
    })
  ),
  on(
    setArticlesSearchState,
    (state, action): IArticlesState => ({
      ...state,
      searchState: action.searchState,
    })
  ),
  on(
    getArticleBlob,
    (state): IArticlesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        articleBlob: true,
      },
    })
  ),
  on(
    getArticleBlobSuccess,
    (state, action): IArticlesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        articleBlob: false,
      },
      articleBlob: action.articleBlob,
    })
  ),
  on(
    getArticleBlobFailure,
    (state, action): IArticlesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        articleBlob: false,
      },
      error: { ...state.error, articleBlob: action.error },
    })
  ),
  on(
    unsetArticleBlob,
    (state): IArticlesState => ({
      ...state,
      articleBlob: null,
    })
  ),
  on(
    getArticle,
    (state): IArticlesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        article: true,
      },
    })
  ),
  on(
    getArticleSuccess,
    (state, action): IArticlesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        article: false,
      },
      article: action.article,
    })
  ),
  on(
    getArticleFailure,
    (state, action): IArticlesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        article: false,
      },
      error: { ...state.error, article: action.error },
    })
  ),
  on(
    unsetArticle,
    (state): IArticlesState => ({
      ...state,
      article: null,
    })
  ),
  on(
    articlesListComponentInitialized,
    (state): IArticlesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        articles: true,
      },
    })
  ),
  on(
    getArticlesSuccess,
    (state, action): IArticlesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        articles: false,
      },
      initialCategoryArticles: action.articles,
      userMappedCategoryArticles: action.articles,
    })
  ),
  on(
    getArticlesFailure,
    (state, action): IArticlesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        articles: false,
      },
      error: { ...state.error, articles: action.error },
    })
  ),
  on(
    unsetArticles,
    (state): IArticlesState => ({
      ...state,
      initialCategoryArticles: null,
      userMappedCategoryArticles: null,
    })
  ),
  on(articlesSortAndSearchResult, (state, action): IArticlesState => {
    return {
      ...state,
      userMappedCategoryArticles: action.articles,
    };
  })
);
