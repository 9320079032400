import { IArticleItem } from '@app/shared/models/interfaces/article/article-item.interface';
import { IArticleListItem } from '@app/shared/models/interfaces/article/article-list-item.interface';
import { Sort } from '@app/shared/models/types/sort.type';
import { createAction, props } from '@ngrx/store';

export const setActiveArticlesCategoryUuid = createAction(
  '[Articles] Set current articles category uuid',
  props<{
    categoryUuid: string;
  }>()
);

export const setArticlesSearchState = createAction(
  '[Articles] Set articles search state',
  props<{
    searchState: string;
  }>()
);

export const getArticleBlob = createAction(
  '[Articles] Get article blob',
  props<{
    pathologyUuid: string;
  }>()
);

export const getArticleBlobSuccess = createAction(
  '[Articles] Get article blob with success',
  props<{
    articleBlob: Blob;
  }>()
);

export const getArticleBlobFailure = createAction(
  '[Articles] Get article blob with failure',
  props<{
    error: string;
  }>()
);

export const unsetArticleBlob = createAction('[Article] Remove article blob');

export const getArticle = createAction(
  '[Articles] Get article',
  props<{
    pathologyUuid: string;
  }>()
);

export const getArticleSuccess = createAction(
  '[Articles] Get article with success',
  props<{
    article: IArticleItem;
  }>()
);

export const getArticleFailure = createAction(
  '[Articles] Get article with failure',
  props<{
    error: string;
  }>()
);

export const unsetArticle = createAction('[Article] Remove article');

export const articleComponentInitialized = createAction(
  '[Articles] Article component initialized',
  props<{
    pathologyUuid: string;
    categoryUuid: string;
  }>()
);

export const articleComponentDestroyed = createAction(
  '[Articles] Article component destroyed'
);

export const getArticles = createAction(
  '[Articles] Get articles',
  props<{
    categoryUuid: string;
  }>()
);

export const getArticlesSuccess = createAction(
  '[Articles] Get articles with success',
  props<{
    articles: IArticleListItem[];
  }>()
);

export const getArticlesFailure = createAction(
  '[Articles] Get articles with failure',
  props<{
    error: string;
  }>()
);

export const unsetArticles = createAction('[Article] Remove articles list');

export const articlesListComponentInitialized = createAction(
  '[Articles] Articles list component initialized',
  props<{
    categoryUuid: string;
  }>()
);

export const sortAndSearchArticles = createAction(
  '[Articles] Sort and search articles',
  props<{
    sortType: Sort;
    searchValue: string;
  }>()
);

export const articlesSortAndSearchResult = createAction(
  '[Articles] Articles sort and search result',
  props<{
    articles: IArticleListItem[];
  }>()
);
